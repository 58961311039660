import { GenericVehicleDetails, parseFilterValues } from '@finn/ua-vehicle';
import { CTA } from '@finn/ui-components';
import { NumericHeadingSizes, ProductCard, Slider } from '@finn/ui-modules';
import { useMemo } from 'react';

import { usePartnerDiscount } from '~/hooks/usePartnerDiscount';
import { useProductListItems } from '~/modules/products/list/api';
import { Filters } from '~/types/filter';
import { CTAData } from '~/types/UIModules';

type Props = {
  title?: string;
  headingStyle?: NumericHeadingSizes;
  filter?: Filters;
  cta?: CTAData;
  baseURL?: string;
  urlParams?: string;
  isForIframe?: boolean;
  isInPLP?: boolean;
  hidePrice?: boolean;
  forceB2BPrice?: boolean;
  vehicles?: GenericVehicleDetails[];
};

const ProductSlider = ({
  title,
  headingStyle,
  filter = {},
  cta,
  baseURL,
  urlParams,
  isForIframe = false,
  isInPLP = false,
  hidePrice = false,
  forceB2BPrice = false,
  vehicles,
}: Props) => {
  const partnerDiscount = usePartnerDiscount();
  const filters = useMemo(() => parseFilterValues(filter), [filter]);
  const { products } = useProductListItems(
    {
      filters,
      zipCode: undefined,
    },
    { fallbackData: { results: vehicles, offset: 0, total_results: 0 } }
  );

  return (
    <>
      {products.length > 0 && (
        <Slider
          title={title}
          verticalSpacing={3}
          titleHeadingSize={headingStyle}
          isForIframe={isForIframe || isInPLP}
        >
          {products.map((vehicle, idx) => (
            <div
              key={vehicle.id}
              data-testid="product-card"
              className="sm:w-[360px w-[280px]"
            >
              <ProductCard
                vehicle={vehicle}
                parentTitle={title}
                shouldShowPlaceholder={false}
                position={idx + 1}
                baseURL={baseURL}
                urlParams={urlParams}
                isForIframe={isForIframe}
                hidePrice={hidePrice}
                forceB2BPrice={forceB2BPrice}
                partnerDiscount={partnerDiscount}
              />
            </div>
          ))}
        </Slider>
      )}
      {cta && (
        <div className="container">
          <div className="mt-12 sm:mt-14">
            <CTA data={cta.metadata} />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductSlider;
